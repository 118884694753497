import { CiEdit } from "react-icons/ci";
// import CustomerImage from "../../../assets/images/customerImage.png";
// import { FaStar } from "react-icons/fa6";
import { Link } from "react-router-dom";
import {
  ArrowRight2,
  Book1,
  Heart,
  I24Support,
  LogoutCurve,
  Money,
  SecurityTime,
} from "iconsax-react";
import copy from 'copy-to-clipboard';
import { Layout } from "../Layout/Layout";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "Redux/reducer";
import { User2, Users2 } from "lucide-react";
import { useGetUserProfile } from '../../../utils/api';
import { FaCopy } from "react-icons/fa6";
import { toast } from "react-toastify";
import { Success } from "assets/icon";
import { useState, useEffect } from 'react';
const profileLinks = [
  {
    icon: <Users2 size={18} />,
    title: "Referral",
    path: "/customer/referral",
  },
  {
    icon: <Money size={18} />,
    title: "Payment Method",
    path: "/customer/payment-method",
  },
  {
    icon: <Heart size={18} />,
    title: "Favourite",
    path: "/customer/favorites",
  },
  {
    icon: <I24Support size={18} />,
    title: "Support & FAQs",
    path: "/rider/support",
  },
  {
    icon: <Book1 size={18} />,
    title: "About",
    path: "/customer/about/",
  },
  {
    icon: <SecurityTime size={18} />,
    title: "Login & Security",
    path: "/customer/change-password",
  },
  {
    icon: <SecurityTime size={18} />,
    title: "Privacy and Policy",
    path: "/privacy",
  },
];

const CustomerProfile = () => {
  const [fetchBaseUrl, setFetchBaseUrl] = useState("")
  const getBaseUrl = () => {
    const { protocol, hostname, port } = window.location;
    const baseUrl = `${protocol}//${hostname}${port ? `:${port}` : ""}`;
    setFetchBaseUrl(baseUrl)
  };

  const { currentUser } = useSelector((state: RootState) => state.user);
  useGetUserProfile()
  const navigate = useNavigate();
  const dispatch = useDispatch<any>();
  const handleCopy = () => {
    copy(`${fetchBaseUrl}/customer/signup?referralCode=${currentUser?.referralCode}` ?? "")

    toast.success("Code Copied", { icon: Success })
  }

  useEffect(() => {
    getBaseUrl()
  }, [])

  return (
    <Layout>
      <main className="pb-24">
        <div className="flex justify-between items-center">
          <h1 className="font-semibold text-xl">Profile</h1>
          <Link to={"/customer/edit-profile"}>
            <CiEdit size={25} />
          </Link>
        </div>

        {/* Avatar and Rider info */}
        <div className="flex flex-col items-center py-10 border-b-[3px] border-b-grey-50">
          {/* Avatar */}
          <div className="w-20 h-20 border grid place-content-center rounded-full overflow-hidden">
            {currentUser?.profileImage ? <img src={currentUser.profileImage.url} alt="profileImage" /> : <User2 size={40} />}
          </div>
          <h2 className="mt-2 font-semibold text-xl">
            {currentUser?.fullName}
          </h2>
          <span className="mt-1 text-xs text-[#999999]">
            {currentUser?.phoneNumber}
          </span>
          <span className="mt-1 text-xs text-[#999999] flex items-center space-x-2">
            <div>Referral Code</div>: <div>{currentUser?.referralCode ?? "N/A"}</div>  <button onClick={handleCopy}><FaCopy className="text-primary-100" /></button>
          </span>
          <div className="mt-1 px-2 1 capitalize flex items-center gap-2 rounded-full border border-[#FFD176] text-[#FFBB33] text-[9px] bg-[#FFF8EB]">
            {currentUser?.badge}
          </div>
        </div>

        {/* Account settings */}
        <div className="py-6">
          <h1 className="font-semibold text-xl">Account Settings</h1>

          {/* Links */}
          <div className="mt-4">
            {profileLinks.map((link, i) => (
              <Link
                key={i}
                to={link.path}
                className="flex justify-between items-center py-4 border-b border-b-[#F4F4F4]"
              >
                <div className="flex items-center gap-3">
                  <div className="w-8 h-8 flex justify-center items-center rounded-full bg-[#f4f4f4]">
                    {link.icon}
                  </div>
                  <h2>{link.title}</h2>
                </div>
                <ArrowRight2 color="#BABABA" size={20} />
              </Link>
            ))}
            <button
              onClick={() => {
                navigate("/auth/signin");
                dispatch({
                  payload: null,
                  type: "LOGOUT",
                });
              }}
              className="w-full flex justify-between items-center py-4 border-b border-b-[#F4F4F4]"
            >
              <div className="flex items-center gap-3">
                <div className="w-8 h-8 flex justify-center items-center rounded-full bg-[#FFECEC]">
                  <LogoutCurve color="#E83E3E" size={18} />
                </div>
                <h2>Logout</h2>
              </div>
            </button>
          </div>
        </div>
        {/* <Navbar /> */}
      </main>
    </Layout>
  );
};

export default CustomerProfile;
