import { Error } from "assets/icon";
import { toast } from "react-toastify";
import * as yup from "yup";

export const loginValidationSchema = yup.object().shape({
  email: yup
    .string()
    .email("Email must be a valid email")
    .required("Email is required"),
  password: yup
    .string()
    .min(6, "Password must be at least 6 characters")
    .required("Password is required"),
});

export const customerRegisterValidationSchema = yup.object().shape({
  firstName: yup.string().required("First Name is required"),
  lastName: yup.string().required("Last Name is required"),
  phoneNumber: yup.string().required("Phone Number is required"),
  referredBy: yup.string().optional(),
  email: yup
    .string()
    .email("Email must be a valid email")
    .required("Email is required"),
  password: yup
    .string()
    .required("Password is required")
    .min(6, "Password must be at least 6 characters"),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("password")], "Passwords must match")
    .required("Confirm Password is required"),
  location: yup.string().required("Please select your location"),
  lga: yup.string().required("Please select your local government"),
});

export const riderRegisterValidationSchema = yup.object().shape({
  firstName: yup.string().required("First Name is required"),
  lastName: yup.string().required("Last Name is required"),
  phoneNumber: yup.string().required("Phone Number is required"),
  email: yup
    .string()
    .email("Email must be a valid email")
    .required("Email is required"),
  password: yup
    .string()
    .required("Password is required")
    .min(6, "Password must be at least 6 characters")
    .max(15, "Password cannot be at more than 15 characters"),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("password")], "Passwords must match")
    .required("Confirm Password is required"),
  location: yup.string().required("Please select your location"),
  lga: yup.string().required("Please select your local government"),

  preference: yup
    .string()
    .oneOf(["food_bike_rider", "logistic_partner"], "Invalid option")
    .required("This field is required"),
});

export const riderCompleteRegistrationValidationSchema = yup.object().shape({
  businessName: yup.string().required("Business Name is required"),
  plateNumber: yup.string().required("Business Name is required"),
  vehicleType: yup.string().required("Vehicle Type is required"),
  driverLicense: yup.string().required("Driver License is required"),
});

export const vendorRegisterValidationSchema = yup.object().shape({
  fullName: yup.string().required("Full Name is required"),
  phoneNumber: yup.string().required("Phone Number is required"),
  category: yup.string().required("Vendor Category is required"),
  businessName: yup.string().required("Business Name is required"),
  cac: yup.string().required("CAC Number is required"),
  email: yup
    .string()
    .email("Email must be a valid email")
    .required("Email is required"),
  password: yup
    .string()
    .required("Password is required")
    .min(6, "Password must be at least 6 characters")
    .max(15, "Password cannot be at more than 15 characters"),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("password")], "Passwords must match")
    .required("Confirm Password is required"),
  address: yup.object().shape({
    streetAddress: yup.string().required("Street Address is required"),
    nearestLandmark: yup.object().shape({
      location: yup.string().required("Landmark is required"),
      zone: yup.string().required("Landmark is required"),
    }),
  }),
  location: yup.string().required("Please select your location"),
});

const LandmarkValidation = yup.object().shape({
  location: yup.string().required("Landmark is required"),
  zone: yup.string().required("Landmark is required"),
});
const addressValSchema = yup.object().shape({
  streetAddress: yup.string().required("Street address required"),
  nearestLandmark: LandmarkValidation,
});

export const vendorUpdateValidationSchema = yup.object().shape({
  phoneNumber: yup.string().required("Phone Number is required"),
  category: yup.string().required("Vendor Category is required"),
  businessName: yup.string().required("Business Name is required"),
  cacNumber: yup.string().required("CAC Number is required"),
  location: yup.string().required("location is required"),
  address: addressValSchema,
});

export const userProfileValidationSchema = yup.object().shape({
  firstName: yup.string().required("First Name is required"),
  lastName: yup.string().required("Last Name is required"),
  phoneNumber: yup.string().required("Phone Number is required"),
  email: yup.string().email("Email must be a valid email"),
  location: yup.string().required("Please select your location"),
});

export const vendorProfileValidationSchema = yup.object().shape({
  firstName: yup.string().required("First Name is required"),
  lastName: yup.string().required("Last Name is required"),
  phoneNumber: yup.string().required("Phone Number is required"),
  category: yup.string().required("Vendor Category is required"),
  businessName: yup.string().required("Business Name is required"),
  cac: yup.string().required("CAC Number is required"),
  email: yup
    .string()
    .email("Email must be a valid email")
    .required("Email is required"),
  password: yup
    .string()
    .required("Password is required")
    .min(6, "Password must be at least 6 characters")
    .max(15, "Password cannot be at more than 15 characters"),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("password")], "Passwords must match")
    .required("Confirm Password is required"),
  address: yup.object().shape({
    streetAddress: yup.string().required("Street Address is required"),
    nearestLandmark: yup.object().shape({
      location: yup.string().required("Landmark is required"),
      zone: yup.string().required("Landmark is required"),
    }),
  }),
  location: yup.string().required("Please select your location"),
});

export const riderVehicleUpdateValidationSchema = yup.object().shape({
  vehicleType: yup.string().required("Vehicle type is required"),
  plateNumber: yup.string().required("Plate number is required"),
  driverLicense: yup.string().required("Driver license is required"),
});

export const riderAccountSettlementSchema = yup.object().shape({
  accountName: yup.string().required("Account name is required"),
  accountNumber: yup.string().required("Account number is required"),
  bankCode: yup.string().required("Bank code is required"),
});

export const userChangePasswordSchema = yup.object().shape({
  oldPassword: yup
    .string()
    .required("Password is required")
    .min(6, "Password must be at least 6 characters")
    .max(15, "Password cannot be at more than 15 characters"),
  newPassword: yup
    .string()
    .required("Password is required")
    .min(6, "Password must be at least 6 characters")
    .max(15, "Password cannot be at more than 15 characters"),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("newPassword")], "Passwords must match")
    .required("Confirm Password is required"),
});

export function validateData(data: any) {
  // Define the required properties
  if (data.length === 0) {
    toast.error("At least 1 day need to be selected", {
      icon: Error,
    });
  }
  const requiredProperties = ["day", "from", "to"];

  // Iterate over each object in the array
  for (let i = 0; i < data.length; i++) {
    const item = data[i];

    // Check if the current object has all required properties
    for (let prop of requiredProperties) {
      if (!item.hasOwnProperty(prop) || item[prop] === "") {
        // If any required property is missing or empty, return false

        if (prop === "from") {
          toast.error(`Start Time Cannot be empty`, {
            icon: Error,
          });
        } else {
          toast.error(`End Time Cannot be empty`, {
            icon: Error,
          });
        }

        return false;
      }
    }
  }

  // If all objects have all required properties and they are not empty, return true
  return true;
}
