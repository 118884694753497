import ngflag from "../../../assets/Svg/ngflag.svg";
import { LuEye, LuEyeOff } from "react-icons/lu";
import { useState } from "react";
import {
  Location,
  Form,
  FormItem,
  FormField,
  FormControl,
  Input,
  FormMessage,
  FormLabel,
  Button,
} from "../../composables";
import { customerRegisterValidationSchema } from "../../../validations";
import { yupResolver } from "@hookform/resolvers/yup";
import { AnimatePresence, motion } from "framer-motion";
import { pageAnimation } from "../../../utils/pageTransition";
import { useForm } from "react-hook-form";
import { MdNavigateBefore } from "react-icons/md";
import { Link, useNavigate } from "react-router-dom";
import { errorLogger } from "../../../utils/helper";
import { registration } from "../../../Redux/actions/auth";
import { useDispatch } from "react-redux";
import NaijaStates from 'naija-state-local-government';
import { toast } from "react-toastify";
import { useQueryParam } from "hooks/useQueryParams";



function CustomerSignup() {

  const navigate = useNavigate();
  const dispatch = useDispatch<any>();
  const referralCode = useQueryParam("referralCode")
  const [agree, setAgree] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [isLocationOpen, setIsLocationOpen] = useState(false);
  const [stage, setStage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [isLgaOpen, setIsLgaOpen] = useState(false);
  const [location, setLocation] = useState("");

  const form = useForm({
    mode: "onSubmit",
    resolver: yupResolver(customerRegisterValidationSchema),
    defaultValues: {
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      location: "",
      lga: "",
      password: "",
      confirmPassword: "",
      referredBy: referralCode ?? "",
    },
  });

  const handleSubmit = async (data: any) => {
    if (!agree) {
      toast.error("Terms and conditions agreement needs to be checked");
      return;
    }
    try {
      const payload = {
        fullName: `${data.firstName} ${data.lastName}`,
        email: data.email.toLowerCase(),
        password: data.password,
        confirmPassword: data.confirmPassword,
        phoneNumber: data.phoneNumber,
        location: data.location.toLowerCase(),
        lga: data.lga,
        referredBy: data.referredBy,
      };
      if (data.referredBy === "") delete payload.referredBy

      setIsLoading(true);

      dispatch(registration(payload, navigate, setIsLoading, "Customer"));
    } catch (e: any) {
      errorLogger(e);
      console.log(e);
    }
  };

  return (
    <motion.div
      variants={pageAnimation}
      initial={"hidden"}
      animate={"visible"}
      exit={"exit"}
      className="w-full h-screen sm:max-w-[600px] mx-auto py-8 px-5"
    >
      <div className="">
        {stage > 1 && (
          <Button
            onClick={() => {
              if (stage > 1) setStage((curr) => curr - 1);
            }}
            className="gap-x-1 bg- mb-5 -ml-2"
          >
            <MdNavigateBefore size={30} className="text-xl text-primary-100" />
            <span>Back</span>
          </Button>
        )}

        <h1 className="text-xl font-bold text-grey-900">Create an Account</h1>
        <h2 className="mt-1 text-grey-200 text-sm max-w-[300px]">
          Join our delicious journey today!
        </h2>

        {/* form progress indicator */}
        <div className="mt-5 flex gap-x-5 h-0.5">
          <div className="h-full basis-0 bg-primary-100 grow"></div>
          <div
            className={`h-full basis-0 grow ${stage === 2 ? "bg-primary-100" : "bg-grey-30"
              }`}
          ></div>
        </div>
      </div>

      <Form {...form}>
        <form
          onSubmit={form.handleSubmit((data) => {
            console.log(data);
            handleSubmit(data);
          })}
          className="mt-5"
        >
          {/* // stage one */}
          <div className={`${stage === 1 ? "block" : "hidden"}`}>
            <FormField
              control={form.control}
              name="firstName"
              render={({ field }) => (
                <FormItem className="w-full mt-5">
                  <FormLabel>First Name</FormLabel>
                  <FormControl>
                    <Input
                      placeholder="John."
                      type="text"
                      className="w-full mt-2 py-2 px-5 bg-grey-30 rounded-lg"
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="lastName"
              render={({ field }) => (
                <FormItem className="w-full mt-5">
                  <FormLabel>Last Name</FormLabel>
                  <FormControl>
                    <Input
                      placeholder="Odo."
                      type="text"
                      className="w-full mt-2 py-2 px-5 bg-grey-30 rounded-lg"
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="email"
              render={({ field }) => (
                <FormItem className="w-full mt-5">
                  <FormLabel>Email</FormLabel>
                  <FormControl>
                    <Input
                      placeholder="John@gmail.com"
                      type="email"
                      className="w-full mt-2 py-2 px-5 bg-grey-30 rounded-lg"
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="phoneNumber"
              render={({ field }) => (
                <FormItem className="w-full mt-5">
                  <FormLabel>Phone Number</FormLabel>
                  <FormControl>
                    <div className="relative w-full h-10">
                      <div className="absolute top-2 left-2">
                        {" "}
                        <img className="px-3 py-2" src={ngflag} alt="" />
                      </div>

                      <Input
                        placeholder="09083234532"
                        type="tel"
                        className="w-full mt-2 pl-14 pr-5 bg-grey-30 rounded-lg"
                        {...field}
                      />
                    </div>
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="referredBy"
              render={({ field }) => (
                <FormItem className="w-full mt-5">
                  <FormLabel>Referral Code <span className="text-gray-400 text-xs">(optional)</span> </FormLabel>
                  <FormControl>
                    <Input
                      disabled={!!(referralCode as string)}
                      defaultValue={(referralCode as string)}
                      placeholder="4rvgk"
                      type="text"
                      className="w-full mt-2 py-2 px-5 bg-grey-30 rounded-lg"
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <div className="my-10 flex flex-col items-center">
              <Button
                onClick={async (e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  const isValid = await form.trigger([
                    "firstName",
                    "lastName",
                    "email",
                    "phoneNumber",
                  ]);

                  if (isValid) {
                    console.log(form.getValues());
                    setStage(2);
                  }
                }}
                className="w-full py-2 px-3 rounded-xl bg-primary-100 text-white"
              >
                Continue
              </Button>
            </div>
          </div>

          {/* // stage two */}
          <div className={`${stage === 2 ? "block" : "hidden"}`}>
            <FormField
              control={form.control}
              name="location"
              render={({ field }) => (
                <FormItem className="w-full mt-5">
                  <FormLabel>Location</FormLabel>
                  <FormControl>
                    <Input
                      placeholder="Abuja"
                      readOnly
                      type="text"
                      onFocus={() => {
                        setIsLocationOpen(true);
                      }}
                      className="w-full mt-2 bg-grey-30 rounded-lg"
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="lga"
              render={({ field }) => (
                <FormItem className="w-full mt-5">
                  <FormLabel>Local Government</FormLabel>
                  <FormControl>
                    <Input
                      placeholder="Surulere"
                      readOnly
                      type="text"
                      onFocus={() => {
                        setIsLgaOpen(true);
                      }}
                      className="w-full mt-2 bg-grey-30 rounded-lg"
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />



            <AnimatePresence initial={false} mode="wait">
              {isLocationOpen && (
                <Location
                  closeLocation={() => {
                    setIsLocationOpen(!isLocationOpen);
                  }}
                  selectLocation={(value) => {
                    console.log(value);

                    form.setValue("location", value || "");
                    if (value === "Minna") {
                      setLocation("Niger")
                    }
                    else if (value === "Ibadan") {
                      setLocation("Oyo")
                    }
                    else if (value === "Jos") {
                      setLocation("Plateau")
                    }
                    else if (value === "Ilorin") {
                      setLocation("Kwara")
                    } else {
                      setLocation(value)
                    }
                  }}
                />
              )}
            </AnimatePresence>
            <AnimatePresence initial={false} mode="wait">
              {isLgaOpen && (
                <Location
                  closeLocation={() => {
                    setIsLgaOpen(!isLgaOpen);

                  }}
                  list={NaijaStates.lgas(location).lgas}
                  selectLocation={(value) => {
                    form.setValue("lga", value || "");
                  }}
                  label="LGA"
                />
              )}
            </AnimatePresence>

            <FormField
              control={form.control}
              name="password"
              render={({ field }) => (
                <FormItem className="w-full mt-5">
                  <FormLabel>Password</FormLabel>
                  <FormControl>
                    <div className="w-full relative h-10">
                      <Input
                        placeholder="Enter your password"
                        type={showPassword ? "text" : "password"}
                        className="w-full mt-2 pl-4 pr-14 bg-grey-30 rounded-lg"
                        {...field}
                      />
                      <div
                        className="absolute -translate-y-1/2 top-1/2 right-5 cursor-pointer"
                        onClick={() => setShowPassword((prev) => !prev)}
                      >
                        {showPassword ? (
                          <LuEyeOff size={20}></LuEyeOff>
                        ) : (
                          <LuEye size={20}></LuEye>
                        )}
                      </div>
                    </div>
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="confirmPassword"
              render={({ field }) => (
                <FormItem className="w-full mt-5">
                  <FormLabel> Confirm Password</FormLabel>
                  <FormControl>
                    <div className="w-full relative h-10">
                      <Input
                        placeholder="Enter your password again"
                        type={showConfirmPassword ? "text" : "password"}
                        className="w-full mt-2 pl-4 pr-14 bg-grey-30 rounded-lg"
                        {...field}
                      />
                      <div
                        className="absolute -translate-y-1/2 top-1/2 right-5 cursor-pointer"
                        onClick={() => setShowConfirmPassword((prev) => !prev)}
                      >
                        {showConfirmPassword ? (
                          <LuEyeOff size={20}></LuEyeOff>
                        ) : (
                          <LuEye size={20}></LuEye>
                        )}
                      </div>
                    </div>
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <div className="mt-5 flex space-x-2 items-center">
              <input type="checkbox" name="" id="" onChange={
                (e) => {
                  setAgree(e.target.checked)
                }
              } />
              <p>I Agree to Jekaeat's <Link className="text-primary-100" to="/privacy">Terms and Condition</Link> </p>
            </div>

            <div className="mt-10 flex flex-col items-center">
              <Button
                type="submit"
                className="w-full h-12 py-2 px-3 rounded-xl bg-primary-100 text-white"
              >
                {isLoading ? (
                  <span className="inline-block animate-spin h-4 w-4 rounded-full border-4 border-l-white border-b-white border-t-gray-400 border-r-gray-400"></span>
                ) : (
                  "Sign Up To Order"
                )}
              </Button>
            </div>
          </div>
          <div className="flex justify-center mt-4">
            <span className="">
              have an account?{" "}
              <Link className="font-bold" to={"/auth/signin"}>
                Sign in
              </Link>
            </span>
          </div>
        </form>
      </Form>
    </motion.div>
  );
}

export default CustomerSignup;
