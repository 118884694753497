import { ACCESS_KEY, SECRET_ACCESS_KEY } from "utils/constant.util";
import { uploadFile } from "react-s3";
import Compressor from "compressorjs";
import { Buffer } from "buffer";
import { useState } from "react";

window.Buffer = Buffer;
const S3_BUCKET = "jekaeatocean";
const REGION = "us-east-1";

const config = {
  bucketName: S3_BUCKET,
  dirName: "FoodImages" /* optional: FoodImages, Logos */,
  region: REGION,
  accessKeyId: ACCESS_KEY,
  secretAccessKey: SECRET_ACCESS_KEY,
};
export const useFileUploadHandler = () => {
  const [imageUrl, setImageUrl] = useState("");
  const [imageLoader, setImageLoader] = useState(false);
  const [directory, setDirectory] = useState("");

  config.dirName = directory ?? config.dirName;
  const handleFileUpload = async (file: any) => {
    if (file) {
      setImageLoader(true);
      new Compressor(file, {
        quality: 0.8,
        width: 600,
        success(result) {
          console.log(result.size);
          uploadFile(file, config) // Ensure uploadFile and config are defined and accessible
            .then((data: any) => {
              const { location } = data;
              setImageUrl(location);
              setImageLoader(false);
            })
            .catch((err: any) => {
              setImageLoader(false);
              console.error(err);
            });
        },
      });
    }
  };

  return {
    ImageUrl: imageUrl.replace(/ /g, "+"),
    imageLoader,
    setImageUrl,
    handleFileUpload,
    setDirectory,
  };
};
